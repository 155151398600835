async function csrfFetch(url, options = {}) {
    // set options.method to 'GET' if there is no method
    options.method = options.method || "GET";
    // set options.headers to an empty object if there is no headers
    options.headers = options.headers || {};

    // if the options.method is not 'GET', then set the "Content-Type" header to
    // "application/json" and set the "X-CSRF-Token" header to the value of
    // "X-CSRF-TOKEN" in `sessionStorage`
    if (options.method.toUpperCase() !== "GET") {
        if(!options.headers["Content-Type"] && !(options.body instanceof FormData)) {
            options.headers['Content-Type'] ||= 'application/json';
        }
        options.headers["X-CSRF-Token"] = sessionStorage.getItem("X-CSRF-Token");
    }

    // call fetch with the url and the updated options hash
    const res = await fetch(url, options);

    // if the response status code is 400 or above, then throw an error with the
    // error being the response
    if (res.status >= 400) throw res;

    // if the response status code is under 400, then return the response to the
    // next promise chain
    return res;
}

export default csrfFetch;


// this is the old one before mike helped me implement the photos one above.
// async function csrfFetch(url, options = {}) {
//     // set options.method to 'GET' if there is no method
//     options.method = options.method || "GET";
//     // set options.headers to an empty object if there is no headers
//     options.headers = options.headers || {};

//     // if the options.method is not 'GET', then set the "Content-Type" header to
//     // "application/json" and set the "X-CSRF-Token" header to the value of
//     // "X-CSRF-TOKEN" in `sessionStorage`
//     if (options.method.toUpperCase() !== "GET") {
//         options.headers["Content-Type"] = options.headers["Content-Type"] || "application/json";
//         options.headers["X-CSRF-Token"] = sessionStorage.getItem("X-CSRF-Token");
//     }

//     // call fetch with the url and the updated options hash
//     const res = await fetch(url, options);

//     // if the response status code is 400 or above, then throw an error with the
//     // error being the response
//     if (res.status >= 400) throw res;

//     // if the response status code is under 400, then return the response to the
//     // next promise chain
//     return res;
// }

// export default csrfFetch;
