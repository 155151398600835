import React from 'react';
import './ProfileIndex.css';

export default function ProfileIndex() {

  return (

    <>

    </>

  )

}
