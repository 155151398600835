import React from 'react';
import './ProfileFeed.css';

export default function ProfileFeed() {

  return (

    <>

    </>

  )

}
